<template>
  <div class="is-flex is-flex-direction-column is-desktop">
    <div class="column is-full">
      <page-title>Página inicial</page-title>
      <div class="columns is-multiline">
          <div class="field column is-12">
              <div class="tabs">
                  <ul>
                  <li v-bind:class="this.liveDesktopVisivel ? 'is-active' : null"><a @click="_ativarAba('live')">LiveDesktop</a></li>
                  </ul>
              </div>
          </div>
          <div class="field column is-12">
              <liveDesktop v-if="this.liveDesktopVisivel" />
          </div>
      </div>
    </div>
    <br>
  </div>

</template>

<script>

import liveDesktop from '../Inicio/liveDesktop.vue'
import PageTitle from '../../components/PageTitle/index.vue'

export default {
  name: 'cadastroEdicao',
  components: {
    liveDesktop,
    'page-title': PageTitle
  },

  data () {
    return {
      liveDesktopVisivel: true
    }
  },

  methods: {

    _atribuirFalseParaTodasAsAbas () {
      this.liveDesktopVisivel = false
    },

    _ativarAba (aba) {
      this._atribuirFalseParaTodasAsAbas()
      switch (aba) {
        case 'live' : this.liveDesktopVisivel = true; break
      }
    }
  }
}
</script>

<style>

</style>
